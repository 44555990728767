import { colors } from "./colors";

const fontSizes = {
  0: 14,
  1: 16,
  2: 18,
  3: 20,
  4: 24,
  5: 28,
  6: 34,
  7: 40,
  8: 48,
  9: 58,
};

// https://system-ui.com/theme/
export const theme = {
  colors,
  fontSizes: {
    ...fontSizes,
    h1: fontSizes[6],
    h1Mobile: fontSizes[5],
    h2: fontSizes[5],
    h2Mobile: fontSizes[4],
    h3: fontSizes[4],
    h3Mobile: fontSizes[3],
    h4: fontSizes[3],
    h4Mobile: fontSizes[2],
    h5: fontSizes[2],
    h5Mobile: fontSizes[1],
    p: fontSizes[1],
    pMobile: fontSizes[0],
    button: fontSizes[2],
  },
  space: [0, 6, 9, 12, 18, 24, 36, 48, 74, 96, 148, 192, 296],
  breakpoints: {
    desktop: {
      pxSize: 1240,
      gridColumns: 12,
    },
    landscape: {
      pxSize: 1024,
      gridColumns: 12,
    },
    portrait: {
      pxSize: 768,
      gridColumns: 12,
    },
    mobile: {
      pxSize: 552,
      gridColumns: 12,
    },
  },
  containerWidths: {
    default: 1024,
    small: 644,
  },
};
