import React from "react";

const body =
  typeof window === "object" ? document.querySelector("body") : undefined;

export const useTabbingListener = () => {
  React.useEffect(() => {
    const handleClick = () => body?.classList.remove("tabbing");
    const handleTab = (event: KeyboardEvent) => {
      if (event.key === "Tab") body?.classList.add("tabbing");
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleTab);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleTab);
    };
  }, []);
};
