import { WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from "gatsby";
import React from "react";
import styled from "styled-components";

import { RootProvider } from "./src/RootProvider";

const LayoutWrapper = styled.div`
  overflow-x: hidden;
  background: white;
`;

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => (
  <LayoutWrapper>{element}</LayoutWrapper>
);

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => (
  <RootProvider>{element}</RootProvider>
);
