export const color = {
  gray1: "#212B36",
  gray2: "#454F5A",
  gray3: "#647380",
  gray4: "#929EAA",
  gray5: "#C4CDD5",
  gray6: "#DFE3E8",
  gray7: "#EFF2F5",
  gray8: "#F4F6F8",
  primary: "#5F685A",
  oliveLight: "#B8C0B5",
  olive: "#5F685A",
  secondary: "#24231F",
  creme: "#F1EEEA",
  tertiary: "#E4E4E4",
  white: "#FFF",
  info: "#F3EBE6",
  text: "#333333",
  red1: "#E54E33",
  red2: "#F6C1B8",
  red3: "#FBE6E3",
} as const;

export const colors = {
  ...color,
} as const;
