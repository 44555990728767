import React from "react";
import { ThemeProvider } from "styled-components";

import { useTabbingListener } from "./common/components/useTabbingListener";
import { theme } from "./common/styles/theme";

import "./common/styles/thirdparty/slick.css";
import "./common/styles/cssReset.css";
import "./common/styles/globalStyles.scss";

export const RootProvider = ({ children }: { children: React.ReactNode }) => {
  useTabbingListener();

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};
